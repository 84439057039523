<template lang="pug">
  .actions
    AppIconButton.action-delete(
      useAppIcon
      icon="trash"
      @click="onHandleRemoveItem"
    )
    AppIconButton.action-open(
      :disabled="!isSelectedCarsAndShops"
      :icon="item.isOpened ? 'chevron-up' : 'chevron-down'"
      @click="$emit('toggle-item-open')"
    )
</template>

<script>
  // mixins
  import withConfirmation from "@/mixins/withConfirmation"

  export default {
    props: {
      item: Object,
      isSelectedCarsAndShops: Boolean
    },

    mixins: [withConfirmation],

    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    methods: {
      onHandleRemoveItem() {
        this.$confirm({
          title: this.$t("car_class_matching.delete_confirmation", { name: this.item.name }),
          resolve: {
            handler: () => {
              this.$emit("delete-item")
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"

  .actions
    display: inline-flex
    padding-top: 11px
    justify-content: space-between
    width: 60px
    height: 100%

    .action-delete,
    .action-open
      border: none
      +icon-button($default-purple)
</style>
